import { z } from "zod"
import { assetSources, availableColors, categories, cigs, ecigs, model, neoOffers, numberPrefixes, veloSampling, samplingPacks } from "./data"
import { useWebformStore } from "./store"

export const userVerificationSchema = z.object({
  emailAddress: z.string().trim().email({ message: "Podany adres e-mail jest nieprawidłowy." }),
  numberPrefix: z.enum(numberPrefixes.map(e => e.value), {
    errorMap: () => ({ message: "To pole jest wymagane." })
  }),
  mobileNumber: z.string().trim().regex(/^\d+$/, { message: "Numer telefonu musi zawierać tylko cyfry." }).
    min(4, { message: "Numer telefonu jest zbyt krótki." }).
    max(15, { message: "Numer telefonu jest zbyt długi." }),
  // inspirationClubConsent: z.boolean()
})

export const otpSchema = z.object({
  otpEmail: z.string().length(4, { message: "Kod powinien składać się z 4 znaków." }),
  otp: z.string().length(4, { message: "Kod powinien składać się z 4 znaków." })
})

export const offerSchema = z.object({
  selectedOffers: z.object({
    "Glo": z.object({
      model: z.enum(model.get("Glo").map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      color: z.enum([...availableColors.values()].flat().map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      serialNumber: z.string().min(1, { message: "To pole jest wymagane." }).optional(),
      assetSource: z.enum(assetSources.get("Glo").map(e => e.label), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
    }).optional(),
    "Glo_HUTD": z.object({
      model: z.enum(model.get("Glo").map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      color: z.enum([...availableColors.values()].flat().map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      serialNumber: z.string().min(1, { message: "To pole jest wymagane." }).optional(),
      assetSource: z.enum(assetSources.get("Glo_HUTD").map(e => e.label), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      samplingOne: z.enum(samplingPacks.map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }).optional(),
      samplingTwo: z.enum(samplingPacks.map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }).optional(),
    }).refine((data) => {
      if (["GLO solo / POP", "GLO solo / KASOTERMINAL"].includes(data.assetSource)) {
        return true
      }
      return data.samplingOne && data.samplingTwo;
    }).optional(),
    Vuse: z.object({
      model: z.enum(model.get("Vuse").map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      color: z.enum([...availableColors.values()].flat().map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
      assetSource: z.enum(assetSources.get("Vuse").map(e => e.label), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      })
    }).optional(),
    Velo: z.object({
      sampling: z.enum(veloSampling.map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      }),
    }).optional(),
    Neo: z.object({
      neoOffers: z.enum(neoOffers.map(e => e.value), {
        errorMap: () => ({ message: "To pole jest wymagane." })
      })
    }).optional(),

    InspirationClub: z.boolean().optional()
  })
}).superRefine((val, ctx) => {
  let offers = Object.keys(val.selectedOffers ?? {})

  // IC is checkbox, it always will have value, 
  // so we need to filter it out if it's false
  if (!val.selectedOffers.InspirationClub) {
    offers = offers.filter(e => e !== "InspirationClub")
  }

  if (offers.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "",
      inclusive: true,
      fatal: true,
    })
  }

  if (offers.includes("InspirationClub") && offers?.length === 1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Zaznaczono tylko InspirationClub, wybierz dodatkową ofertę",
      inclusive: true,
      fatal: true,
      path: ["InspirationClub"],
    })
  }

  // if (offers.includes("Glo") && offers.includes("Vuse")) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: "Nie można łączyć oferty Glo i Vuse razem",
  //     inclusive: true,
  //     fatal: true,
  //     path: ["Glo"],
  //   })
  // }

  // if (offers.includes("Vuse") && !offers.includes("Velo")) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: "Przy wybraniu oferty Vuse, oferta Velo jest wymagana",
  //     inclusive: true,
  //     fatal: true,
  //     path: ["Vuse"],
  //   })
  // }

  if (offers.includes("Neo") && (offers.includes("Velo") || offers.includes("Glo") || offers.includes("Vuse"))) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Oferta Neo może jedynie łączyć się z ofertą InspirationClub",
      inclusive: true,
      fatal: true,
      path: ["Neo"],
    })
  }
})


export const webformSchema = z.object({
  hostessCode: z.string().min(1, { message: "Niepoprawny kod HST." }),
  tourPlan: z.string().min(1, { message: "Niepoprawny numer akcji." }),
  registerLocation: z.string().min(1, { message: "Lokalizacja wymagana" }).optional(),
  firstName: z.string().trim().min(1, { message: "Podaj imię konsumenta." }).min(2, { message: "Imię jest zbyt krótkie (min. to 2 znaki)." }),
  lastName: z.string().trim().min(1, { message: "Podaj nazwisko konsumenta." }).min(2, { message: "Nazwisko jest zbyt krótkie (min. to 2 znaki)." }),
  birthDate: z.coerce.date({
    errorMap: () => ({ message: "Podaj datę urodzenia." })
  }).max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), { message: "Konsument nie ma 18 lat. Sprawdź poprawność daty." }).or(z.literal('')),
  billingPostalCode: z.string().regex(/^[0-9]{2}-[0-9]{3}$/, { message: "Kod pocztowy powinien składać się z 5 cyfr." }).optional().or(z.literal('')),
  // registerLocation: z.string().min(1, { message: "To pole jest wymagane." }).transform(val => {
  //   const correctValue = val.substring(0, val.indexOf('_'))
  //   if (correctValue === "null") {
  //     return null
  //   }
  //   return correctValue
  // }).nullish(),
  category: z.enum(categories.map(e => e.value), {
    errorMap: () => ({ message: "To pole jest wymagane." })
  }),
  ageVerified: z.literal(true, {
    errorMap: () => ({ message: "Zgoda wymagana." })
  }),
  inspirationClub: z.literal(true, {
    errorMap: () => ({ message: "Zgoda wymagana." })
  }).optional(),
  personalDataRegistrationAuthorisation: z.literal(true, {
    errorMap: () => ({ message: "Zgoda wymagana." })
  }),
  newsletterCommercialAuthorization: z.literal(true, {
    errorMap: () => ({ message: "Zgoda wymagana." })
  }),
  mobileOptIn: z.boolean().optional(),
  socialMediaOptIn: z.boolean().optional(),
  hypercareOptIn: z.boolean().optional(),
  // needed for refine....
  selectedOffers: z.any().optional(),
  isHoreca: z.any().optional()
}).refine(val => {
  if(val.isHoreca && !val.registerLocation) {
    return false
  }

  const selectedOffersKeys = Object.keys(val.selectedOffers)

  if (selectedOffersKeys.some(e => ["Glo_HUTD", "Vuse"].includes(e))) {
    const selectedSKUGLO = val.selectedOffers?.["Glo_HUTD"]?.assetSource
    const selectedSKUVuse = val.selectedOffers?.["Vuse"]?.assetSource

    const allConsentsRequired = (
      assetSources.get("Glo_HUTD")?.find(e => e.label === selectedSKUGLO)?.requireAllConsents ||
      assetSources.get("Vuse")?.find(e => e.label === selectedSKUVuse)?.requireAllConsents
    ) ?? false

    if (!allConsentsRequired) {
      return true
    }

    return (allConsentsRequired && [
      val.hypercareOptIn,
      val.socialMediaOptIn,
      val.mobileOptIn,
    ].every(e => e === true)
    )

    // if (additionalConsentsOnSKUs.includes(selectedSKU) && !val.hypercareOptIn) {
    //   return false
    // }
  }

  if (selectedOffersKeys.includes("Velo") && selectedOffersKeys.length === 1) {
    return ([
      val.hypercareOptIn,
      val.socialMediaOptIn,
      val.mobileOptIn,
    ].every(e => e === true))
  }

  return true
})
